import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DefaultApiService } from '../../../generated/api';

export type Tenant = {
  id: string;
  name: string;
};

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  constructor(private apiService: DefaultApiService) {}

  findTenants(userId: string): Observable<Tenant[]> {
    return this.apiService.tenantsGet({ userId }).pipe(map((response) => response.data));
  }
}

/**
 * REST API for anolab
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsersGet200ResponseDataInner } from './users-get200-response-data-inner';


export interface UsersPost201Response { 
    status: UsersPost201Response.StatusEnum;
    message: UsersPost201Response.MessageEnum;
    data: UsersGet200ResponseDataInner;
}
export namespace UsersPost201Response {
    export type StatusEnum = 201;
    export const StatusEnum = {
        NUMBER_201: 201 as StatusEnum
    };
    export type MessageEnum = 'Created';
    export const MessageEnum = {
        Created: 'Created' as MessageEnum
    };
}



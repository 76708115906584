import { createAction, props } from '@ngrx/store';
import { User } from '../../services/users.service';

export const loadCurrentUser = createAction('[Auth] Load Current User');

export const loadCurrentUserSuccess = createAction(
  '[Auth] Load Current User Success',
  props<{ currentUser: User }>(),
);

export const loadCurrentUserFailure = createAction('[Auth] Load Current User Failure');

import { createAction, props } from '@ngrx/store';
import { Tenant } from '../../services/tenants.service';

export const loadAvailableTenants = createAction(
  '[Tenants] Load Available Tenants',
  props<{ userId: string }>(),
);

export const loadAvailableTenantsSuccess = createAction(
  '[Tenants] Load Available Tenants Success',
  props<{ availableTenants: Tenant[] }>(),
);

export const loadAvailableTenantsFailure = createAction('[Tenants] Load Available Tenants Failure');

export const selectTenant = createAction(
  '[Tenants] Select Tenant',
  props<{ selectedTenant: Tenant | null }>(),
);

// USA
export const locale = {
  lang: 'en',
  data: {
    AMPLIFY: {
      'Account recovery requires verified contact information':
        'Account recovery requires verified contact information',
      'Add your Profile': 'Add your Profile',
      'Add your Website': 'Add your Website',
      'Back to Sign In': 'Back to Sign In',
      'Change Password': 'Change Password',
      Changing: 'Changing',
      Code: 'Code',
      'Confirm Password': 'Confirm Password',
      'Confirm Sign Up': 'Confirm Sign Up',
      'Confirm SMS Code': 'Confirm SMS Code',
      'Confirm TOTP Code': 'Confirm TOTP Code',
      Confirm: 'Confirm',
      'Confirmation Code': 'Confirmation Code',
      Confirming: 'Confirming',
      'Create a new account': 'Create a new account',
      'Create Account': 'Create Account',
      'Creating Account': 'Creating Account',
      'Dismiss alert': 'Dismiss alert',
      Email: 'Email',
      'Enter your Birthdate': 'Enter your Birthdate',
      'Enter your code': 'Enter your code',
      'Enter your Confirmation Code': 'Enter your Confirmation Code',
      'Enter your Email': 'Enter your Email',
      'Enter your Family Name': 'Enter your Family Name',
      'Enter your Given Name': 'Enter your Given Name',
      'Enter your Middle Name': 'Enter your Middle Name',
      'Enter your Name': 'Enter your Name',
      'Enter your Nickname': 'Enter your Nickname',
      'Enter your Password': 'Enter your Password',
      'Enter your phone number': 'Enter your phone number',
      'Enter your Preferred Username': 'Enter your Preferred Username',
      'Enter your username': 'Enter your username',
      'Forgot password?': 'Forgot password?',
      'Forgot your password?': 'Forgot your password?',
      'Hide password': 'Hide password',
      'It may take a minute to arrive': 'It may take a minute to arrive',
      Loading: 'Loading',
      'New password': 'New password',
      or: 'or',
      Password: 'Password',
      'Phone Number': 'Phone Number',
      'Please confirm your Password': 'Please confirm your Password',
      'Resend Code': 'Resend Code',
      'Reset your password': 'Reset your password',
      'Reset your Password': 'Reset your Password',
      'Send code': 'Send code',
      'Send Code': 'Send Code',
      Sending: 'Sending',
      'Setup TOTP': 'Setup TOTP',
      'Show password': 'Show password',
      'Sign in to your account': 'Sign in to your account',
      'Sign In with Amazon': 'Sign In with Amazon',
      'Sign In with Apple': 'Sign In with Apple',
      'Sign In with Facebook': 'Sign In with Facebook',
      'Sign In with Google': 'Sign In with Google',
      'Sign in': 'Sign in',
      'Sign In': 'Sign In',
      'Signing in': 'Signing in',
      Skip: 'Skip',
      Submit: 'Submit',
      Submitting: 'Submitting',
      Username: 'Username',
      'Verify Contact': 'Verify Contact',
      Verify: 'Verify',
      'We Emailed You': 'We Emailed You',
      'We Sent A Code': 'We Sent A Code',
      'We Texted You': 'We Texted You',
      'Your code is on the way. To log in, enter the code we emailed to':
        'Your code is on the way. To log in, enter the code we emailed to',
      'Your code is on the way. To log in, enter the code we sent you':
        'Your code is on the way. To log in, enter the code we sent you',
      'Your code is on the way. To log in, enter the code we texted to':
        'Your code is on the way. To log in, enter the code we texted to',
    },
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    PAGETITLE: {
      DASHBOARD: 'Dashboard',
      ANOMALY: 'Anomaly Stream',
      ACCOUNT: 'Account',
      OVERVIEW: 'Overview',
      SETTINGS: 'Settings',
      BILLING: 'Billing & Tokens',
      PERMISSIONS: 'Permissions & Roles',
      USERMANAGE: 'User-Management',
      PROFILEUSER: 'User',
      INSTANCES: 'Instances',
      DATAVIEWER: 'Dataviewer',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    SIDEBAR: {
      DATASOURCE: 'Datasources',
      PAGES: 'Pages',
      INSTANCES: 'Instances',
    },
    NAVBAR: {
      PROFILE: 'My Profile',
      ACCOUNT: 'Account',
      BILLING_TOKEN: 'Billing & Tokens',
      LANGUAGE: 'Language',
      SIGNOUT: 'Sign Out',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Sign in',
        SUBTITLE: '...and detect anomalies',
        SIGNUPGOOGLE: 'Sign in with Google',
        SIGNUPAPPLE: 'Sign in with Apple',
        OR_TEXT: 'or with email',
        WRONGLOGIN: 'The login details are incorrect',
        PW_LABEL: 'Password',
        BUTTON: 'Continue',
        NO_MEMBER: 'Not a Member yet?',
        SIGNUP: 'Request now',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        SUBTITLE: '...and detect anomalies',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        REG_INCORRECT: 'The registration details are incorrect',
        LABEL_FIRSTNAME: 'Firstname',
        LABEL_LASTNAME: 'Lastname',
        LABEL_PHONE: 'Phone',
        LABEL_COMPANY: 'Company',
        LABEL_PW: 'Password',
        LABEL_CONFIRM_PW: 'Confirm Password',
        NO_MATCH_PW: "'Passsword' and 'Confirm Password' didn't match.",
        ACCEPT_TERMS: 'I Accept the ',
        TERMS: 'TERMS',
        BTN_SUBMIT: 'Submit',
        HAVE_ACCOUNT: 'Already have an Account?',
        SIGN_IN: 'Sign in',
        WAIT: 'Please wait...',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
      LINKS: {
        TERMS: 'Terms',
        IMPRINT: 'Imprint',
        CONTACT: 'anolab',
      },
    },
    ACCOUNT: {
      BALANCE: 'Current tokens',
      OVERVIEW: 'Overview',
      SETTINGS: 'Settings',
      BILLING: 'Billing & Tokens',
      PERMS: 'Permission & Roles',
      USER: 'User-Management',
      BILLINGTAP: {
        SEARCH: 'Search',
        DATE: 'Date',
        NODATE: 'last 30 Days',
        TOOLTIPDATE: 'reset Date',
        TRANSACTIONTYPE: 'Transactiontype',
        AMOUNT: 'Amount',
        AGENT: 'Agent',
        FILTER: 'Filter',
        DATEHINT: 'If you do not enter a date, the last 30 days are displayed.',
        ALL: 'all',
        RESET: 'Reset',
        APPLY: 'Apply',
        OVERVIEW: 'Overview',
        CURRENTTOKENS: 'Current tokens',
        BALANCE: 'Balance',
        CONTINGENT: 'Contigent',
        USAGE: 'Usage',
        SETTLED: 'Settled',
        TRANSACTIONS: 'Transactions',
        NOTRANSACTIONS: 'No transactions found',
        DATETIME: 'Date / Time',
        TOKENAMOUNT: 'Token amount',
        PAGINATION: {
          PREVIOUS: 'Previous',
          NEXT: 'Next',
        },
        TRANSACTIONTYPES: {
          ADJUSTMENT: 'Adjustment',
          USAGE: 'Usage',
          BILLING: 'Billing',
          QUOTA: 'Quota',
          ANY: 'Any Transaction Type',
        },
        AGENTS: {
          DATAFLOW: 'Data Flow',
          DATASTORAGE: 'Data Storage',
          WATCHER: 'Watcher',
          ANY: 'Any Agent',
        },
      },
      PERMISSIONS: {
        TOTAL: 'Total users with this role:',
        CREATE: 'Create',
        VIEW: 'View Role',
        EDIT: 'Edit Role',
        NEW_ROLE: 'Add new role',
      },
      USER_MANAGE: {
        SEARCH: 'Search User',
        ADD_USER: 'Add User',
        EDIT: 'Edit',
        DELETE: 'Delete',
        USER: 'User',
        ROLE: 'Role',
        ACTIONS: 'Actions',
        EMPTY: 'No Users found',
        SELECTROLES: 'Select one or more Roles',
      },
      DELETE_MODAL: {
        TITLE: 'Delete',
        TEXT1: 'Are you sure you want to delete the user ',
        TEXT2: '',
        BTN_CONFIRM: 'Yes, delete',
        BTN_DISMISS: 'No, cancel',
      },
      EDIT_MODAL: {
        TITLE: 'Edit User ',
        FIRSTNAME: 'Firstname',
        LASTNAME: 'Lastname',
        ROLE: 'User role(s)',
        SELECT: 'Select Role/s',
        EDIT: 'Edit user role(s)',
        ABORTEDIT: 'Abort edit user role(s)',
        BTN_SAVE: 'Save',
        BTN_CANCEL: 'Cancel',
      },
      CREATE_MODAL: {
        TITLE: 'Create new user',
        BTN_SAVE: 'Send invitation',
        BTN_CANCEL: 'Cancel',
      },
    },
    PROFILE: {
      USER_DETAILS: {
        TITLE: 'Profile Details',
        EMAIL: 'Email address',
        FULLNAME: 'Fullname',
        ROLE: 'Role',
        USER: 'User',
      },
    },
    DATAVIEWER: {
      TAB: {
        OBJECTS: 'Objects',
        ENTITIES: 'Entities',
      },
      TABLE: {
        IMPORT: 'Import',
        IMPORT_ADD: '(Date & Time)',
        COUNTER: 'Total Entities',
      },
      PAYLOAD_MODAL: {
        HEADER: 'Payload',
        BTN_CLOSE: 'Close',
      },
    },
    FILTERMENU: {
      VISIBILITY: 'Visibility',
      VISIBLE: 'visible',
      UNVISIBLE: 'unvisible',
      STATE: 'State',
      CONFIRMED: 'Confirmed',
      UNCONFIRMED: 'not confirmed',
      IMPORT: 'Import',
      IMPORT_ADD: '(Date)',
      FIRST_IMPORT: 'First Import',
      LAST_IMPORT: 'Last Import',
    },
    SWEETALERT: {
      CONFIRM: 'Got it!',
      SUCCESS_TITLE: 'Success!',
      ERROR_TITLE: 'Failed!',
      CREATE_MODAL: {
        SUCCESS_TEXT: 'User created!',
        ERROR_TEXT: 'User COULD NOT be created!',
      },
      EDIT_MODAL: {
        SUCCESS_TEXT: 'User updated!',
        ERROR_TEXT: 'User COULD NOT be updated!',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    INSTANCES: {
      TITLE: 'My Instances',
      NEW: 'New Instance',
      EDIT: 'Edit',
      STATE: {
        CHOOSE: 'Choose a State',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
      },
      BTN_STATE: {
        GOOD: 'All good',
        ISSUE: 'New Issues',
        STRIKING: 'Striking',
      },
      PRODUCTS: 'Products',
      ORDERS: 'Orders',
      EMPLOYEES: 'Employees',
      PAYMENTS: 'Payments',
      INCOMING: 'Incoming',
      OUTGOING: 'Outgoing',
    },
  },
};

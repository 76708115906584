import { createReducer, on } from '@ngrx/store';
import { Tenant } from '../../services/tenants.service';
import * as tenantActions from './tenants.actions';

export type State = {
  selectedTenant: Tenant | null;
  availableTenants: Tenant[];
};

export const initialState: State = {
  selectedTenant: null,
  availableTenants: [],
};

export const reducer = createReducer(
  initialState,
  on(tenantActions.selectTenant, (state, { selectedTenant }) => ({ ...state, selectedTenant })),
  on(tenantActions.loadAvailableTenantsSuccess, (state, { availableTenants }) => ({
    ...state,
    availableTenants,
  })),
);

export const key = 'tenants';

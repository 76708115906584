import { createReducer, on } from '@ngrx/store';
import { User } from '../../services/users.service';
import * as usersActions from './auth.actions';

export type State = {
  currentUser: User | null;
};

export const initialState: State = {
  currentUser: null,
};

export const reducer = createReducer(
  initialState,
  on(usersActions.loadCurrentUserSuccess, (state, { currentUser }) => ({ ...state, currentUser })),
  on(usersActions.loadCurrentUser, (state) => {
    return state;
  }),
);

export const key = 'auth';

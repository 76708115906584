import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  appVersion: 'v8.2.3',
  isMockEnabled: false,
  aws: {
    cognito: {
      userPoolId: 'eu-central-1_bf7soRtGS',
      webClientId: '72as79q22qfof6fqtbq5t3nehv',
    },
  },
  apiBasePath: 'https://api.app.anolab.de/dev',
};

import { Injectable } from '@angular/core';
import { EMPTY, from, map, mergeMap, Observable } from 'rxjs';
import { DefaultApiService } from '../../../generated/api';
import { AuthenticationService } from './authentication.service';

export type CurrentUser = {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  roles: { [tenantId: string]: string[] } | null;
};

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(
    private apiService: DefaultApiService,
    private authService: AuthenticationService,
  ) {}

  findCurrentUser(): Observable<CurrentUser> {
    return from(this.authService.attributes()).pipe(
      map((attr) => (attr.sub as string) ?? EMPTY),
      mergeMap((sub: string) => this.apiService.userSubGet({ sub })),
      map((response) => response.data),
    );
  }
}

import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { tenants } from '.';
import { TenantsService } from '../../services/tenants.service';

export const loadAvailableTenants = createEffect(
  (actions$ = inject(Actions), tenantsService = inject(TenantsService)) => {
    return actions$.pipe(
      ofType(tenants.actions.loadAvailableTenants),
      exhaustMap(({ userId }) =>
        tenantsService.findTenants(userId).pipe(
          map((availableTenants) =>
            tenants.actions.loadAvailableTenantsSuccess({ availableTenants }),
          ),
          catchError(() => of(tenants.actions.loadAvailableTenantsFailure())),
        ),
      ),
    );
  },
  { functional: true },
);

export const selectFirstTenant = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(tenants.actions.loadAvailableTenantsSuccess),
      map(({ availableTenants }) =>
        tenants.actions.selectTenant({ selectedTenant: availableTenants.at(0) ?? null }),
      ),
    );
  },
  { functional: true },
);

import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { auth } from '.';
import { CurrentUserService } from '../../services/current-user.service';
import { tenants } from '../tenants';

export const loadCurrentUser = createEffect(
  (actions$ = inject(Actions), currUsrService = inject(CurrentUserService)) =>
    actions$.pipe(
      ofType(auth.actions.loadCurrentUser),
      exhaustMap(() =>
        currUsrService.findCurrentUser().pipe(
          map((currentUser) => auth.actions.loadCurrentUserSuccess({ currentUser })),
          catchError(() => of(auth.actions.loadCurrentUserFailure())),
        ),
      ),
    ),
  { functional: true },
);

export const loadCurrentUserSuccess = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(auth.actions.loadCurrentUserSuccess),
      map(({ currentUser }) => tenants.actions.loadAvailableTenants({ userId: currentUser.id })),
    ),
  { functional: true },
);
